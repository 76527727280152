

















import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import TeacherForm from './teacher-form.vue';

class PageTeacherRequest extends PageRequest {
    name: string = '';
    type: number = null;
    group: number = null;
}

@Component({
    components: { ListView, TeacherForm, FormBase }
})
export default class Teachers extends ListBase {
    entity: string = 'teacher';
    pagerequest: PageTeacherRequest = new PageTeacherRequest();
    filterForm: boolean = true;

    filterItems = [
        this.gItem({ type: 'text', prop: 'name', label: 'Buscar por DNI o Nombre', size: 6, onchange: this.afterSave, hideDetails: true }),
        this.gItem({ type: 'spacer', size: 6 }),
    ]

    columns = [
        this.gCol('code', 'Codigo', 'text'),
        this.gCol('document_full', 'Documento', 'text'),
        this.gCol('fullname', 'Apellidos y Nombres', 'text'),
        this.gCol('active', 'Habilitado', 'text'),
        this.defaultActions()
    ]

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        if (list != undefined)
            await list.getpage();
    }

    mounted() {

    }

    created() {

    }
}